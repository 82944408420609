import React, { Component } from "react"
import styles from "./../../styles.module.less"
import Scrollspy from "react-scrollspy"
import { Link } from "gatsby"
import Reveal from "react-reveal/Reveal"

import mainLogo from "./../../img/mainLogo.png"
import logo1 from "./../../img/logo1.jpg"
import logo2 from "./../../img/logo2.jpg"
import logo3 from "./../../img/logo3.jpg"
import logo4 from "./../../img/logo4.jpg"
import logo5 from "./../../img/logo5.jpg"
import logo6 from "./../../img/logo6.jpg"
import logo7 from "./../../img/logo7.jpg"
import logo8 from "./../../img/logo8.jpg"
import bg1 from "./../../img/bg1.jpg"

import typeography1_hiring from "./../../img/img1.jpg"
import typography2_title from "./../../img/img4.jpg"
import typography3_whistle from "./../../img/img2.jpg"
import typography4_realwork from "./../../img/img5.jpg"
import typography5_hiring from "./../../img/img3.jpg"

import page from "./../../img/page.png"

export default class Style extends Component {
  render() {
    if (typeof window !== "undefined") {
      // eslint-disable-next-line global-require
      require("smooth-scroll")('a[href*="#"]')
    }
    return (
      <div>
        <Reveal>
          <div
            id="style"
            className={[
              styles.thesisbrandguidelinesstyle,
              styles.Style,
              styles.animated,
              styles.fadeInUp,
              styles.animatedFadeInUp,
            ].join(" ")}
          >
            <div className={styles.wrap}>
              <div className={styles.p1}>
                <div className={styles.headline}>Our style.</div>
                <div className={styles.headline2}>
                  Your guide to <i>making</i> on behalf of Thesis.
                </div>
                <Link to="/styleguide" className={styles.back_btn}>
                  <svg
                    preserveAspectRatio="none"
                    viewBox="0 0 17 16"
                    className={styles.arrow}
                  >
                    <path d="M 8.999984741210938 0 L 16.99998474121094 8 L 8.999984741210938 16 L 7.599990844726563 14.5999755859375 L 13.19999694824219 9 L 0 9 L 0 7 L 13.19998168945313 7 L 7.5999755859375 1.4000244140625 L 8.999984741210938 0 Z" />
                  </svg>
                  <div className={styles.back}>Back</div>
                </Link>
              </div>

              <nav className={styles.stickySideMenuStyle}>
                <Scrollspy
                  items={["section-1", "section-2", "section-3", "section-4"]}
                  currentClassName={styles.isCurrent}
                >
                  <li>
                    <a className={styles.logo} href="#section-1" tabIndex="0">
                      Logo
                    </a>
                  </li>
                  <li>
                    <a className={styles.color} href="#section-2" tabIndex="0">
                      Color
                    </a>
                  </li>
                  <li>
                    <a
                      className={styles.typography}
                      href="#section-3"
                      tabIndex="0"
                    >
                      Typography
                    </a>
                  </li>

                  <li>
                    {" "}
                    <a
                      className={styles.templates}
                      href="#section-4"
                      tabIndex="0"
                    >
                      Templates
                    </a>
                  </li>
                </Scrollspy>
              </nav>

              <section className={styles.logoSection}>
                <div id="section-1" className={styles.spacer}>
                  <div className={styles.mainLogo}>
                    <div className={styles.mainPar}>
                      <h2>The logo.</h2>
                      <h4>
                        It started as a classic serif letterform before being
                        redrawn and reshaped by hand. Highlighting the duality
                        of our approach, our mark juxtaposes measured academia
                        with raw creative energy.
                      </h4>
                    </div>
                    <div className={styles.logoImg}>
                      <img src={mainLogo} alt="thesis agency logo" />
                      <div className={styles.logoDownload}>
                        <span>
                          Download:
                          {/* <a>eps</a> |  */}
                          <a
                            href="https://eroi-s3.s3.amazonaws.com/thesis/styleguide/ThesisLogo.png"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            jpg
                          </a>
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className={styles.columns}>
                    <div className={styles.logoImg}>
                      <img src={logo1} alt="thesis agency logo" />
                      <p>Allow equidistant space around the mark.</p>
                      {/* <div className={styles.logoDownload}>
                                            <span>
                                                Download: <a>eps</a> | <a>png</a>
                                            </span>
                                        </div> */}
                    </div>
                    <div className={styles.logoImg}>
                      <img src={logo2} alt="thesis agency logo" />
                      <p>50% black fill. Use on light backgrounds.</p>
                      {/* <div className={styles.logoDownload}>
                                            <span>
                                                Download: <a>eps</a> | <a>png</a>
                                            </span>
                                        </div> */}
                    </div>

                    <div className={styles.logoImg}>
                      <img src={logo3} alt="thesis agency logo" />
                      <p>100% white fill. Use on dark backgrounds.</p>
                      {/* <div className={styles.logoDownload}>
                                            <span>
                                                Download: <a>eps</a> | <a>png</a>
                                            </span>
                                        </div> */}
                    </div>
                  </div>
                  <div className={styles.columns}>
                    <div className={styles.logoImg}>
                      <img src={logo4} alt="thesis agency logo" />
                      <p>
                        Never alter the letter weight to be thicker or thinner.
                      </p>
                    </div>
                    <div className={styles.logoImg}>
                      <img src={logo5} alt="thesis agency logo" />
                      <p>Never add drop shadows, outlines, or glows.</p>
                    </div>
                    <div className={styles.logoImg}>
                      <img src={logo6} alt="thesis agency logo" />
                      <p>Never stretch, skew, or distort the logo.</p>
                    </div>
                    <div className={styles.logoImg}>
                      <img src={logo7} alt="thesis agency logo" />
                      <p>Never use the logo to replace the word “Thesis.”</p>
                    </div>
                    <div className={styles.logoImg}>
                      <img src={logo8} alt="thesis agency logo" />
                      <p>
                        Never override the logo color—use provided hues only.
                      </p>
                    </div>
                  </div>
                  <div className={styles.line}></div>

                  <div className={styles.logoPrint}>
                    <h4>
                      Place the logo in one of the four corners, rotating 90
                      degrees—clockwise—as needed.
                    </h4>
                    <img src={page} alt="" />
                  </div>
                </div>
              </section>

              <section className={styles.colors}>
                <div id="section-2" className={styles.spacer}>
                  <div className={styles.mainPar}>
                    <h2>The colors.</h2>
                    <h4>
                      Our versatile design system is built to grow with us while
                      holding onto our roots.
                    </h4>
                  </div>
                  <div className={styles.palette}>
                    <div className={styles.secondPar}>
                      <h2>Brand palette.</h2>
                      <h4>
                        Inspired by our home in the Pacific Northwest, our
                        no-blue corporate palette is traditional but modern.
                      </h4>
                    </div>
                    <div className={styles.mainPalette}>
                      <div>
                        <div className={styles.square}>
                          <span>
                            Deep Northwest CMYK: 79 59 69 74 RGB: 19 36 31 HEX:
                            #13241F
                          </span>
                        </div>
                        <div className={styles.desc}>
                          Primary color: dark green, nearly black.
                        </div>
                      </div>
                      <div>
                        <div className={styles.columns}>
                          <div className={styles.Sm}>
                            <div className={styles.darkGr}>
                              {" "}
                              <span>80%</span>
                            </div>
                            <div className={styles.lightGr}>
                              <span>40%</span>
                            </div>
                          </div>
                          <div className={styles.Sm}>
                            <div className={styles.midGray}>
                              <span>60%</span>
                            </div>
                            <div className={styles.lighterGr}>
                              <span>20%</span>
                            </div>
                          </div>
                        </div>
                        <div className={styles.desc}>
                          Secondary colors: Deep Northwest in 20% increments.
                        </div>
                      </div>
                      <div>
                        <div className={styles.Lg}>
                          <div className={styles.twoLg}>
                            <div className={styles.black}></div>
                            <div className={styles.gray}></div>
                          </div>
                          <div className={styles.white}></div>
                        </div>
                        <div className={styles.desc}>
                          Supporting colors: 100% black, 50% black, and 100%
                          white.
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className={styles.palette}>
                    <div className={styles.secondPar}>
                      <h2>
                        Seasonal palette:<span> fall / winter 2019</span>
                      </h2>
                      <h4>
                        Sometimes you need to break the mold, especially when
                        working digitally. With a rotating, seasonal palette
                        there’s room to grow, surprise, and delight.
                      </h4>
                    </div>
                    <div className={styles.mainPalette}>
                      <div className={styles.bgImg}>
                        <img src={bg1} alt="" />
                      </div>
                      <div>
                        <div className={styles.Lg}>
                          <div className={styles.twoLg}>
                            <div className={styles.purple}></div>
                            <div className={styles.darkpurple}></div>
                          </div>
                          <div className={styles.twoLg}>
                            <div className={styles.orange}></div>
                            <div className={styles.lightorange}></div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* <div className={styles.viewLink}>
                                        <a>View past seasonal palettes</a>
                                    </div> */}
                  </div>
                </div>
              </section>

              <section className={styles.typography}>
                <div id="section-3" className={styles.spacer}>
                  <div className={styles.mainPar}>
                    <h2>The typography.</h2>
                    <h4>
                      Something old. Something new. A couple of workhorse
                      typefaces we use to wield our words.
                    </h4>
                  </div>

                  <div className={styles.twoCol}>
                    <div className={styles.circular}>
                      <h2>Circular</h2>
                      <h4>
                        For headlines and body copy of marketing and web
                        experiences. Circular is a modern, sans-serif typeface
                        that’s available in four weights—book, medium, bold, and
                        black—and most world alphabets, each with matching
                        italics. Find it <a>here.</a>
                      </h4>
                    </div>
                    <div className={styles.garamond}>
                      <h2>Garamond FB</h2>
                      <h4>
                        For adding style and emphasis, sparingly. Garamond is a
                        classic typeface that’s used by some of the leading
                        brands on the planet—including the Thesis wordmark.{" "}
                        <span>
                          Find it <a>here.</a>
                        </span>
                      </h4>
                    </div>
                  </div>

                  <div className={styles.typeScale}>
                    <h2>Type scale.</h2>
                    <h4>
                      Here’s how to use these typefaces ... in the way the
                      letter gods intended.
                    </h4>
                  </div>

                  <div className={styles.threeCol}>
                    <div className={styles.headers}>
                      <div>
                        <h2>Circular Pro Black 60pt</h2>
                        <span className={styles.xxl}>Header XXL</span>
                      </div>
                      <div>
                        <h2>Circular Pro Black 56pt</h2>
                        <span className={styles.xl}>Header XL</span>
                      </div>
                      <div>
                        <h2>Circular Pro Black 48pt</h2>
                        <span className={styles.lg}>Header LG</span>
                      </div>
                      <div>
                        <h2>Circular Pro Black 40pt</h2>
                        <span className={styles.md}>Header MD</span>
                      </div>
                      <div>
                        <h2>Circular Pro Black 32pt</h2>
                        <span className={styles.sm}>Header SM</span>
                      </div>
                      <div>
                        <h2>Circular Pro Black 24pt</h2>
                        <span className={styles.xs}>Header XS</span>
                      </div>
                      <div>
                        <h2>Circular Pro Black 18pt</h2>
                        <span className={styles.xxs}>Header XXS</span>
                      </div>
                    </div>
                    <div className={styles.body}>
                      <div>
                        <h2>Circular Pro Book 24pt</h2>
                        <span className={styles.lg}>Body LG</span>
                      </div>
                      <div>
                        <h2>Circular Pro Book 18pt</h2>
                        <span className={styles.md}>Body MD</span>
                      </div>
                      <div>
                        <h2>Circular Pro Book 14pt</h2>
                        <span className={styles.sm}>Body SM</span>
                      </div>
                      <div>
                        <h2>Circular Pro Book 12pt</h2>
                        <span className={styles.xs}>Body XS</span>
                      </div>
                    </div>
                    <div className={styles.font}>
                      <div>
                        <h2>Garamond FB Bold & Italic 28pt</h2>
                        <span className={styles.lg}>
                          Emphasis LG
                          <br />
                        </span>
                        <span className={styles.lg_it}>Emphasis LG</span>
                      </div>
                      <div>
                        <h2>Garamond FB Bold & Italic 24pt</h2>
                        <span className={styles.md}>
                          Emphasis MD
                          <br />
                        </span>
                        <span className={styles.md_it}>Emphasis MD</span>
                      </div>
                      <div>
                        <h2>Garamond FB Bold & Italic 20pt</h2>
                        <span className={styles.sm}>
                          Emphasis SM
                          <br />
                        </span>
                        <span className={styles.sm_it}>Emphasis SM</span>
                      </div>
                      <div>
                        <h2>Garamond FB Bold & Italic 18pt</h2>
                        <span className={styles.xs}>
                          Emphasis XS
                          <br />
                        </span>
                        <span className={styles.xs_it}>Emphasis XS</span>
                      </div>
                    </div>
                  </div>

                  <div className={styles.line}></div>

                  <div className={styles.brand}>
                    <h2>Our brand typography in the wild.</h2>
                    <div className={styles.typeSamples}>
                      <div className={styles.typography1}>
                        <img src={typeography1_hiring} alt="" />
                      </div>
                      <div className={styles.typography2}>
                        <img src={typography2_title} alt="" />
                      </div>
                      <div className={styles.typography3}>
                        <img src={typography3_whistle} alt="" />
                      </div>
                      <div className={styles.typography4}>
                        <img src={typography4_realwork} alt="" />
                      </div>
                      <div className={styles.typography5}>
                        <img src={typography5_hiring} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <section className={styles.templates}>
                <div id="section-4" className={styles.spacer}>
                  <div className={styles.mainPar}>
                    <h2>The templates.</h2>
                  </div>
                  <div className={styles.downloads}>
                    <div>
                      <h4>Business Cards</h4>
                      <div className={styles.logoDownload}>
                        <span>
                          <a
                            href="https://eroi-s3.s3.amazonaws.com/thesis/styleguide/BusinessCards.indd"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Download
                          </a>
                        </span>
                      </div>
                    </div>
                    <div>
                      <h4>Email Signature</h4>
                      <div className={styles.logoDownload}>
                        <span>
                          <a
                            href="https://eroi-s3.s3.amazonaws.com/thesis/styleguide/Thesis%20Email%20Signature%20-%20Copy%20and%20Paste%20-%20DO%20NOT%20EDIT%20THIS%20DOC.docx"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Download
                          </a>
                        </span>
                      </div>
                    </div>
                    <div>
                      <h4>Pitch Deck</h4>
                      <div className={styles.logoDownload}>
                        <span>
                          <a
                            href="https://eroi-s3.s3.amazonaws.com/thesis/styleguide/Thesis%20Presentation%20Template.pptx"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Download
                          </a>
                        </span>
                      </div>
                    </div>
                    <div>
                      <h4>Instagram Cover</h4>
                      <div className={styles.logoDownload}>
                        <span>
                          <a
                            href="https://eroi-s3.s3.amazonaws.com/thesis/styleguide/Instagram_Avatar.psd"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Download
                          </a>
                        </span>
                      </div>
                    </div>
                    <div>
                      <h4>LinkedIn Cover</h4>
                      <div className={styles.logoDownload}>
                        <span>
                          <a
                            href="https://eroi-s3.s3.amazonaws.com/thesis/styleguide/Linkedin%20Cover.png"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Download
                          </a>
                        </span>
                      </div>
                    </div>
                    <div>
                      <h4>Seasonal Backgrounds</h4>
                      <div className={styles.logoDownload}>
                        <span>
                          <a href="" target="_blank" rel="noopener noreferrer">
                            View
                          </a>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <Link to="/styleguide/story" className={styles.bottomCta}>
                <div className={styles.back}>Our story</div>
                <div>
                  <svg
                    preserveAspectRatio="none"
                    viewBox="0 0 17 16"
                    className={styles.arrow}
                  >
                    <path d="M 8.999984741210938 0 L 16.99998474121094 8 L 8.999984741210938 16 L 7.599990844726563 14.5999755859375 L 13.19999694824219 9 L 0 9 L 0 7 L 13.19998168945313 7 L 7.5999755859375 1.4000244140625 L 8.999984741210938 0 Z" />
                  </svg>
                </div>
              </Link>
            </div>
          </div>
        </Reveal>
      </div>
    )
  }
}
