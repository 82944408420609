import React from "react"

import Style from "../../components/StyleGuide/components/Style"
import Layout from "../../components/Layout"
import SEO from "../../components/seo"

const StylePage = () => (
  <Layout backgroundColor={"white"} navColor={"black"}>
    <SEO
      title="Thesis | Our brand style"
      keywords={["thesis agency", "portland agency", "marketing agency"]}
      description="So you want to write or design for Thesis? Here's everything you need to make on behalf of Thesis, a digital agency in Portland, OR."
    />
    <Style />
  </Layout>
)

export default StylePage
